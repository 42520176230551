import {create, path, alias, expr, exact, middleware, incomplete, validate} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';

import {rootRoute} from 'acng/core/config/routes';
import {mainView, stickyView} from 'acng/layout/service/sequence.js';
import {onlyUserOrPreview} from 'acng/userPool/config/middleware';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {inject} from 'acng/core/service/ng';
import {ctxCommentable} from 'acng/comment/model/commentable';
import {picturePoolFeature} from './feature';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

export const pictureSetsRoute = rootRoute.create(
  onlyUserOrPreview,
  // TODO voyager multi parent
  expr(/^(?:\/sedcard\/.+)?\/pictures\/sets/y), //
  mainView(picturePoolFeature, 'pictureSets'),
  // TODO alias is required by onswCategorySelect in sidebar
  alias('pictureSets')
);

rootRoute.create(
  path('/pictures/sets/{type}'),
  validate(({type}) => /^(top|new|classics)$/.test(/** @type {string} */ (type))),
  stickyView(picturePoolFeature, 'list-header'),
);

amateurRoute.create(
  path('/pictures/set/{setId}'),
  mainView(picturePoolFeature, 'pictureSet'),
  middleware((next, prev) => {
    if (next.setId == prev.setId) {
      next.set = prev.set;
      return true;
    }
    const PictureSet = inject('PictureSet');
    return PictureSet.get(/** @type {string} */ (next.setId))
      .then((pictureSet) => {
        next.set = pictureSet;
        ctxCommentable[CTX_PROVIDE](null, {
          type: 'pictures',
          id: pictureSet.id,
          blockWithReason: async () => {
            if (pictureSet.blocked) {
              return 'comment.notCommentable';
            }
            if (!(await pictureSet.getStock())) {
              return 'comment.buyPictureSet';
            }
            return false;
          },
        });
      })
      .catch((reason) => {
        replaceUrl('/#/');
        throw reason;
      });
  }),
  create(
    path('/item'),
    incomplete,
    alias('pictureSlideshow'),
    create(exact('/{item}'), alias('pictureSlideshowImage'))
  )
);
