import {ImageTile} from 'acng/core/tiles/ImageTile';

angular.module('core')
  .factory('AdBannerTile', ['$http', 'user', '$window', 'portal', ($http, user, $window, portal) => {

    const p = {};

    function getBanner(type) {
      if (!p[type]) {
        p[type] = $http.get(`/api/ad-banner?type=${type}`);
      }
      return p[type];
    }

    return class AdBannerTile extends ImageTile
    {
      dontSetBg = true;
      async load(scope, config) {
        if (!(user.isInSplittyGroup(42, 1) || user.isInGroup('age18-25')) || user.isPremium() || portal.shortcut === 'EAM') {
          throw 'no ad banner';
        }
        const res = await getBanner(config || 'default');
        await super.load(scope, {
          src: res.data.imageUrl,
          click: () => $window.open(res.data.clickUrl + '&userid=' + user.uid + '&el=' + encodeURIComponent(btoa(user.email)), '_blank').focus()
        });
        this.image.style.width = '100%';
        this.box.prepend(this.image);
      }
    };
  }]);
