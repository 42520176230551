import {hasFeature} from 'acng/core/service/env';
import {Tile} from 'acng/core/widgets/basic-tile.js';

angular.module('stock')

  .factory('StockTile', ['$injector', 'http', ($injector, http) => {
    const MovieTile = hasFeature('moviePool') ? $injector.get('MovieTile') : false;
    const PictureThumbnail = hasFeature('picturePool') ? $injector.get('PictureThumbnail') : false;
    //const PictureSetTile = hasFeature('picturePool') ? $injector.get('PictureSetTile') : false;
    const PayttachmentTile = hasFeature('messenger') ? $injector.get('PayttachmentTile') : false;

    class StockTile extends Tile
    {
      load(scope, stockitem) {
        scope.stockitem = stockitem;
        switch (stockitem.type) {
          case 'Movie':
            return MovieTile.prototype.load.call(this, scope, stockitem.article_id)
              .catch(async (reason) => {
                if (await this.shouldBeRemoved(stockitem)) {
                  throw reason
                }
              });
          case 'Picture':
            return PictureThumbnail.prototype.load.call(this, scope, stockitem.article_id)
              .catch(async (reason) => {
                if (await this.shouldBeRemoved(stockitem)) {
                  throw reason
                }
            });
          case 'Payttachment':
            return PayttachmentTile.prototype.load.call(this, scope, stockitem.article_id);
          default:{
            throw { msg: 'invalid stockitem', stockitem };
          }
        }
      }

      /**
         * @param {{ article_id: any; type: any; }} stockitem
         */
      shouldBeRemoved(stockitem) {
        return http().post('/api/stock/check/',
            {
                article_id: stockitem.article_id,
                type: stockitem.type
            },
            {
              headers: {'Content-Type': 'application/json'}
            }
          );
      }
      hookname() {
        return 'media';
      }
    }
    return StockTile;
  }]);
