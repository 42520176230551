/**
 * Provides an image cropper for the profile picture of the user.
 * @module
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.93.0
 */

import {defineCompileElement, defineRegistryElement, Engine, NODES, task} from '@acng/frontend-stargazer';
import {set} from '@acng/frontend-bounty/dom/attribute.js';
import {addClass, append, defineElement, onClick} from '@acng/frontend-bounty';
import {connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {rootRoute} from '@acng/frontend-voyager';
import {create, DIV} from '@acng/frontend-bounty/dom/element.js';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {put} from 'acng/core/service/backend';
import {popup} from '@acng/frontend-discovery';
import {t} from 'acng/locale/config/translate';
import {inject} from 'acng/core/service/ng';
import {ANY, guard, RECORD} from '@acng/frontend-rubicon';

defineRegistryElement('onsw-image-cropper', (name) => {
  const transclude = Engine.Transclude(name);
  /** @type {typeof Cropper} */
  let cropper;
  defineCompileElement(name, (_element, _promisses, compiler) => {
    task(
      compiler,
      import('cropperjs').then((module) => {
        cropper = module.default;
      })
    );
  });
  defineElement(
    name,
    class ImageCropper extends HTMLElement {
      #engine = Engine.root;

      /** @type {{format:string, detail:Cropper.Data} | undefined} */
      static crop;
      [connectedCallback]() {
        cropper.setDefaults({});
        this.#engine = transclude(this);
        this.#engine.toElement(this);
        ASSERT: guard(this.#engine.nodes, TEMPLATE);
        set(this.#engine.nodes.crop_image, 'src', `/api/userProfile/crop-info/${rootRoute.globals.image}`);
        /** @type {'normal' | 'wide'} */
        var format = 'normal';
        /** @type {Record<string,number>} */
        var formats = {
          wide: 16 / 9,
          normal: 4 / 3,
        };
        const image = this.#engine.nodes.crop_image;
        const cropperBox = this.#engine.nodes.cropper;
        const button = this.#engine.nodes.crop_btn;

        onClick(button, this.save);

        this.#engine.nodes.crop_image.onload = () => {
          var ratio = image.width / image.height;
          if (ratio > 1.6) {
            format = 'wide';
          }
          if (format === 'normal') {
            const header = query(document, 'header');
            if (header) {
              const h = window.innerHeight - header.offsetHeight - button.offsetHeight - 80;
              image.style.height = `${h}px`;
              image.style.width = 'auto';
              cropperBox.style.height = `${h} px`;
            }
          }
          new cropper(image, {
            viewMode: 1,
            aspectRatio: formats[format],
            checkCrossOrigin: false,
            zoomable: false,
            guides: false,
            movable: false,
            restore: false,
            crop: function (e) {
              ImageCropper.crop = {detail: e.detail, format: format};
            },
            ready: function () {
              var ratioPW = create(DIV);
              addClass(ratioPW, 'crop-pw');
              addClass(ratioPW, format);
              const cropperViewBox = query(cropperBox, '.cropper-view-box');
              if (cropperViewBox) {
                append(cropperViewBox, ratioPW);
              }
            },
          });
        };
      }
      async save() {
        ASSERT: guard(ImageCropper.crop, RECORD(ANY, ANY));
        await put(`userProfile/crop-info/${rootRoute.globals.image}`, ImageCropper.crop, this);
        inject('user').trackActivity();
        await popup(this).info(await t('userPool.imageUpload.cropSuccess'));
        location.hash = '/profile';
      }
      [disconnectedCallback]() {
        this.#engine.disconnect();
      }
    }
  );
});

const TEMPLATE = /* @__PURE__ */ NODES({
  cropper: HTMLElement,
  crop_image: HTMLImageElement,
  crop_btn: HTMLButtonElement,
});
