import {createContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';

import {MessageDraft} from '../model/message-draft.js';

/**
 * The ***draft*** of a `Message` written by the `AuthUser`
 *
 * @type {import('@acng/frontend-relativity').LegacyContext<?MessageDraft>}
 */
export const ctxMessageDraft = createContext();

/**
 * Get the ***draft*** of a `Message` written by the `AuthUser` in the `Context` of the `element`.
 *
 * @param {Element} element
 */
export const getMessageDraft = (element) => ctxMessageDraft[CTX_VALUE](element);

/**
 * @param {HTMLElement} context
 * @param {Amateur | null} amateur
 * @returns {void}
 */
export const provideComposer = (context, amateur) => {
  if (!amateur) {
    ctxMessageDraft[CTX_PROVIDE](context, null);
    return;
  }

  const proxy = new Proxy(new MessageDraft(amateur), {
    set(composer, property, value) {
      switch (property) {
        // Those will trigger provide.
        case 'body':
        case 'attachment':
        case 'allowVoice':
        case 'voice':
        case 'playing':
        case 'wait':
          if (composer[property] != value) {
            // @ts-expect-error TODO why?
            composer[property] = value;

            //DEBUG: console.info(`${MODULE} update`, {proxy, property, value, context});

            ctxMessageDraft[CTX_PROVIDE](context, proxy);
          }
          return true;
        // Those may change silently.
        case 'payload':
        case 'timestamp_ms':
        case 'focusElement':
          composer[property] = value;
          return true;
        // The rest will fail.
        default:
          return false;
      }
    },
  });

  //DEBUG: console.info(`${MODULE} create`, {proxy, context});

  ctxMessageDraft[CTX_PROVIDE](context, proxy);
};
