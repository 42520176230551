/**
 * Contains the logic for the automatic display of active payment bonuses in an overlay the *AuthUser*.
 *
 * This module also ensures that a bonus overlay is displayed one minute after a
 * bonus becomes available or one minute after login.
 * If the user is currently in a livecam show or has any other overlay, the
 * display of that overlay is delayed. The overlay will also delayed if any
 * canvas is active on the page.
 *
 * Also includes:
 * - The special ad overlay for the age group 18-25
 * - The "show a bonus overlay for non premium user after entering the amateur sedcard x times" logic
 *
 * @module PaymentBonus
 * @author Jacob Viertel <jv@onscreen.net>, Nils Engel <ne@onscreen.net>
 * @see [AuthUser](../../userPool/context/auth-user.js)
 */

import {ctxBonusList} from 'acng/payment/context/bonus-list.js';
import {GlobalWatch, value} from '@acng/frontend-relativity';
import {clearTimeout, setTimeout} from '@acng/frontend-bounty';
import {inject, isInjectable} from 'acng/core/service/ng.js';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {on} from '@acng/frontend-bounty/dom/event.js';
import {amateurRoute} from 'acng/amateurPool/config/routes.js';
import {on as onRoute} from '@acng/frontend-voyager';
import {portal} from 'acng/core/service/env.js';

const MODULE = 'payment/run/bonus-overlay';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

(async () => {
  let overlayAdShowed = false;
  let timeout = 0;

  /**
   * null means the overlay was already opened once
   * @type {Record<string,?import('acng/core/service/overlay.js').Overlay>}
   */
  const overlays = {};

  /**
   * @type {?import('acng/core/service/overlay.js').Overlay}
   */
  let amateurRouteOverlay = null;

  /**
   @param {import('../model/bonus').Bonus[]} bonusList
   */
  const autoCloseOverlays = (bonusList) => {
    for (const bonusName in overlays) {
      if (!bonusList.find((bonus) => bonus.name === bonusName)) {
        overlays[bonusName]?.close();
      }
    }

    // @ts-ignore Overlay Scope
    if (amateurRouteOverlay && !bonusList.find((bonus) => bonus.name === amateurRouteOverlay?.scope.bonus.name)) {
      amateurRouteOverlay.close();
    }
  };

  const livecamIsActive = () => {
    if (!isInjectable('Livecam')) {
      return false;
    }

    return inject('Livecam').isActive();
  };

  const check = async () => {
    DEBUG: if (VERBOSE) console.debug(`${MODULE} overlay checks`);

    const onsOverlay = inject('onsOverlay');
    const user = inject('user');
    const showAdOverlay = portal.shortcut !== 'EAM'
      && (user.isInSplittyGroup(42, 1) || user.isInGroup('age18-25'))
      && !user.isPremium();
    const activeCanvas = query(document, 'canvas') !== null;

    timeout = 0;

    if (onsOverlay.count() || livecamIsActive() || activeCanvas) {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} a different overlay is currently displayed => retry after timeout`);
      timeout = setTimeout(check, 10000);
      return;
    }

    if (showAdOverlay && !overlayAdShowed) {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} show ad overlay`);
      onsOverlay.create('adOverlay').open();
      overlayAdShowed = true;
      timeout = setTimeout(check, 60000);
      return;
    }

    const bonusList = value(ctxBonusList);
    const bonus = bonusList.find((bonus) => !(bonus.name in overlays));
    if (bonus) {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} open overlay for bonus`, bonus);
      const overlay = onsOverlay.create('bonusOverlay', {bonus}).open();
      on(overlay, 'close', () => {
        overlays[bonus.name] = null;
      });
      overlays[bonus.name] = overlay;
      timeout = setTimeout(check, 60000);
      return;
    }

    DEBUG: if (VERBOSE) console.debug(`${MODULE} no bonus to display`);
  };

  GlobalWatch(ctxBonusList)((bonusList, previousList) => {
    DEBUG: if (VERBOSE) console.log(`${MODULE} bonus list updated`, bonusList);
    const topBonus = bonusList[0];

    autoCloseOverlays(bonusList);

    if (previousList && topBonus?.name != previousList[0]?.name) {
      clearTimeout(timeout);
      timeout = 0;
    }

    if (!timeout && topBonus) {
      timeout = setTimeout(check, 60000);
    }
  });

  amateurRoute.config(onRoute('enter', () => {
    const bonus = value(ctxBonusList)[0];
    const user = inject('user');
    if (!(amateurRoute.count % 5) && !user.isPremium() && !user.checkFirstLoginWithinDays(5) && bonus) {
      amateurRouteOverlay = inject('onsOverlay').create('bonusOverlay', {bonus}).open();
      on(amateurRouteOverlay, 'close', () => amateurRouteOverlay = null);
    }
  }));

})();
