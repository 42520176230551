import {createGlobalContext} from "@acng/frontend-relativity";
import {CTX_PROVIDE} from "@acng/frontend-relativity/minify";
import {guard} from "@acng/frontend-rubicon";

import {listen} from "acng/core/context/event-bus.js";
import {EVENTBUS_STOCK_ITEM} from "../model/stock.js";

const MODULE = 'userPool/context/stock-update';
const VERBOSE = false;

/**
 * @type {import("@acng/frontend-relativity").LegacyContext<?import("acng/userPool/model/stock").StockItem>}
 */
export const ctxStockUpdate = createGlobalContext(null);

listen('stock-item', (data) => {
  ASSERT: guard(data, EVENTBUS_STOCK_ITEM);
  DEBUG: if (VERBOSE) console.info(MODULE, data.item);

  ctxStockUpdate[CTX_PROVIDE](null, data.item);
});
